// JoinUs/SignIn drawer
import React, { useEffect } from 'react';
import axios, { API } from 'lib/config/axios';
import { useTranslation } from 'next-i18next';
// Material UI Core
import { darken, alpha } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/features/ui-slice';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import { RewardsItemsList } from 'views/common/components/UI/RewardsItemsList/RewardsItemsList';
import { isLocalStorageAvailable } from 'lib/utils/localStroage';
import getLocalizedUrl from 'lib/utils/getLocalizedUrl';
import useSSONavigate from 'views/common/hooks/useSSONavigate';
import SocialLogin from 'views/common/components/Logical/Auth/SocialLogin';

const drawerWidth = '21.5rem';
const mobileDrawerWidth = '20rem';

const StyledSocialLogin = styled(SocialLogin)(({ theme }) => ({
  marginTop: `${theme.spacing(5)} !important`,
  '& .SLGoogle': {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.9),
    },
  },
  '& .SLFacebook': {
    backgroundColor: 'rgb(65, 98, 167)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: alpha('rgb(65, 98, 167)', 0.9),
    },
    '& path': {
      fill: theme.palette.common.white,
    },
  },
}));
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-root': {
    width: drawerWidth,
    flexShrink: 0,
    "& [class*='MuiBackdrop-root']": {
      backgroundColor: 'rgba(0,0,0,0.16)',
    },
    [theme.breakpoints.down('lg')]: {
      width: mobileDrawerWidth,
    },
  },
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    [theme.breakpoints.down('lg')]: {
      width: mobileDrawerWidth,
    },
  },
  '& .drawerHeader': {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.5rem 0 2rem',
    color: theme.palette.common.white,
    backgroundColor: [theme.palette.grey[700]],
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    minHeight: ['5rem', '!important'],
    [theme.breakpoints.down('md')]: {
      minHeight: ['3.5rem', '!important'],
    },
    "& [class*='MuiTypography-root']": {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(20),
    },
  },
  '& .grow': {
    flexGrow: 1,
  },
  '& .form': {
    backgroundColor: [theme.palette.secondary.dark],
    padding: '1.2rem 2rem 2rem',
    '& > *': {
      margin: theme.spacing(1.5, 0),
    },
  },
  '& .formHeader': {
    marginBottom: theme.typography.pxToRem(2),
    color: theme.palette.common.white,
    '& .signIn': {
      fontSize: '1.125rem',
    },
    '& .signUpLabel': {
      fontSize: '0.875rem',
      textAlign: 'right',
      '& .newLabel': {
        textTransform: 'Capetalize',
        color: theme.palette.text.secondary,
      },
      '& .createAccount': {
        fontWeight: 500,
        textTransform: 'uppercase',
      },
    },
  },
  '& .forgotPassword': {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  '& .divider': {
    position: 'relative',
    margin: theme.spacing(2.2, 0),
    '& span': {
      position: 'absolute',
      padding: '0 10px',
      top: '50%',
      textTransform: 'uppercase',
      backgroundColor: [theme.palette.secondary.dark],
      color: theme.palette.divider,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
  },
  '& .google': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: darken(theme.palette.common.white, 0.1),
    },
    '& .googleLogo': {
      marginRight: theme.typography.pxToRem(8),
    },
  },
  '& .facebook': {
    backgroundColor: theme.palette.facebook.main,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: darken(theme.palette.facebook.main, 0.1),
    },
  },
  '& .joinUs': {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    '& .title': {
      fontWeight: 400,
      fontSize: '1.125rem',
      letterSpacing: 0,
      textAlign: 'start',
    },
    '& .joinButton': {
      margin: theme.spacing(1, 0, 0),
      textTransform: 'uppercase',
    },
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.secondary.dark,
    color: 'rgb(153, 223, 221)',
  },
}));
const StyledRewardsItemsList = styled(RewardsItemsList)(({ theme }) => ({
  '& .text': {
    color: theme.palette.common.white,
  },
  '& .RLILogo': {
    '& path': {
      fill: theme.palette.common.white,
    },
  },
}));

const JoinUsDrawer = ({
  open,
  handleDrawerClose,
  handleForgotPasswordOpen,
}) => {
  const { t } = useTranslation();
  const appNavigator = useAppNavigator();
  const dispatch = useDispatch();
  const { moveToERSSOPage } = useSSONavigate();

  const handleErrorResponse = useErrorsHandler();
  const [value, setValue] = React.useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  const handleSignIn = (authenticityToken) => {
    axios
      .post(API.users.signIn, {
        authenticity_token: authenticityToken,
        user: { ...value },
      })
      .then(async (res) => {
        if (res.data.access_token) {
          localStorage.removeItem('signCount');
          if (res.data.authenticity_token) {
            localStorage.setItem(
              'authenticity_token',
              res.data.authenticity_token,
            );
            handleDrawerClose();
          }
          localStorage.setItem('signin', true);
          localStorage.setItem('signin-msg', res.data.message);
          if (typeof ReactNativeWebView !== 'undefined') {
            // eslint-disable-next-line no-undef
            ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'login',
                data: res.data,
              }),
            );
          }
          let returnUrl = window.location.href;
          if (
            PATHS.users.signIn().url === appNavigator.asPath
            || PATHS.users.signUp().url === appNavigator.asPath
          ) {
            returnUrl = getLocalizedUrl(PATHS.home().url, appNavigator.locale);
          }
          moveToERSSOPage(res.data.access_token, returnUrl);
        } else {
          await axios.get(API.users.signOut);
          const signInCount = localStorage.getItem('signCount') || 0;
          if (!signInCount) {
            localStorage.setItem('signCount', signInCount + 1);
            await handleSignIn(authenticityToken);
          } else {
            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: t('fe_er_common_layout:errors.not_authorized'),
              }),
            );
          }
        }
      })
      .catch((error) => {
        try {
          switch (error.response.status) {
            case 401:
              if (error.response.data.error_code === 'ERG002') {
                axios.get(API.users.signOut);
              }
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: error.response.data.message,
                }),
              );
              break;
            default:
              handleErrorResponse(error);
          }
        } catch (e) {
          handleErrorResponse(error);
        }
      });
  };

  const getToken = () => {
    axios
      .get(API.authenticityToken)
      .then((res) => {
        handleSignIn(res.data.authenticity_token);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  useEffect(() => {
    if (isLocalStorageAvailable()) {
      if (localStorage.getItem('signout')) {
        localStorage.removeItem('signout');
        dispatch(
          setSnackbar({
            open: true,
            severity: 'success',
            message: 'You have been signed out successfully!',
          }),
        );
      }
    }
  }, []);
  return (
    <>
      <StyledDrawer
        id="join-us-drawer"
        className="drawer"
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}>
        <div className="drawerHeader">
          <Typography variant="h6" gutterBottom={false} className="grow">
            {t('fe_er_common_layout:navigation.your_account')}
          </Typography>
          <IconButton onClick={handleDrawerClose} size="large">
            <CloseIcon sx={{ color: 'white' }} />
            {/* remove sx later after migrating to v5 */}
          </IconButton>
        </div>
        <form className="form" noValidate autoComplete="off">
          <Box
            className="formHeader"
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h6" className="signIn">
              {t('fe_er_common_layout:navigation.sign_in')}
            </Typography>
            <Typography className="signUpLabel">
              <span className="newLabel">
                {t('fe_er_common_layout:navigation.new')}
                &nbsp;
              </span>
              <NavigatorLink
                className="createAccount"
                href={PATHS.users.signUp({ secure: 'true' })}
                onClick={handleDrawerClose}
                color="primary">
                {t('fe_er_common_layout:create_account')}
              </NavigatorLink>
            </Typography>
          </Box>
          <StyledAlert variant="outlined" severity="info">
            Already have an
            {' '}
            <b>EagleShare.com</b>
            {' '}
            user account? You can use it
            below!
          </StyledAlert>
          <TextField
            fullWidth
            name="email"
            label="Email"
            variant="outlined"
            onChange={handleChange}
            value={value.email}
          />
          <TextField
            type="password"
            fullWidth
            label={t('fe_er_common_layout:navigation.password')}
            variant="outlined"
            name="password"
            onChange={handleChange}
            value={value.password}
            helperText={(
              <Typography
                variant="caption"
                className="forgotPassword"
                onClick={handleForgotPasswordOpen}
                color="primary">
                <b>{t('fe_er_common_layout:forgot_password')}</b>
              </Typography>
            )}
          />
          <Button
            type="button"
            onClick={getToken}
            fullWidth
            variant="contained"
            color="primary"
            xs={{ textTransform: 'uppercase' }}>
            {t('fe_er_common_layout:navigation.sign_in')}
          </Button>
          <Divider className="divider" component="div" variant="fullWidth">
            <span>{t('fe_er_common_layout:navigation.or')}</span>
          </Divider>
          <StyledSocialLogin />
        </form>
        <div className="joinUs grow">
          <Typography
            className="title"
            gutterBottom
            variant="h6"
            align="center"
            sx={{ color: 'common.white' }}>
            {t('fe_er_common_layout:navigation.new_to_eaglerider')}
          </Typography>
          <Button
            className="joinButton"
            fullWidth
            component={NavigatorLink}
            href={PATHS.users.signUp({ secure: 'true' })}
            onClick={handleDrawerClose}
            variant="contained"
            color="primary">
            {t('fe_er_common_layout:navigation.join_us')}
          </Button>
          <Box pt={4}>
            <StyledRewardsItemsList />
          </Box>
        </div>
      </StyledDrawer>
    </>
  );
};

export default JoinUsDrawer;
