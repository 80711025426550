import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BenefitItem } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RLILogo': {
    '& svg': {
      width: theme.spacing(30),
      height: 'auto',
    },
  },
}));
export const StyledBenefitItem = styled(BenefitItem)(({ theme }) => ({
  '& .text': {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'nowrap',
    },
  },
  ' & .BenfitItem-iconBox': {
    lineHeight: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}));

export default StyledBox;
