import React from 'react';
import { Box } from '@mui/material';
import StyledBox, {
  StyledBenefitItem,
} from 'views/common/components/UI/RewardsItemsList/RewardsItemsList.styles';
import RewardProgramLogo from 'views/common/components/UI/Icons/rewards/RewardProgramLogo';
import useRewardBenefitsList from 'views/common/hooks/useRewardBenefitsList';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import PATHS from 'lib/navigation/paths';

const RewardsItemsList = (props) => {
  const { className, logo = true } = props;
  const { rewardBenefitsList } = useRewardBenefitsList();

  return (
    <StyledBox className={className}>
      {logo && (
        <Box mb={3} className="RLILogo">
          <NavigatorLink href={PATHS.rewards()}>
            <RewardProgramLogo />
          </NavigatorLink>
        </Box>
      )}
      {rewardBenefitsList.map((item) => (
        <Box mb={2} key={item.text} className="RSGridItem">
          <StyledBenefitItem
            image={item.image}
            icon={item.icon()}
            text={item.text}
          />
        </Box>
      ))}
    </StyledBox>
  );
};

export { RewardsItemsList };
