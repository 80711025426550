import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const PREFIX = 'BenfitItem';

const classes = {
  benefitContainer: `${PREFIX}-benefitContainer`,
  iconBox: `${PREFIX}-iconBox`,
  helpIcon: `${PREFIX}-helpIcon`,
  tooltip: `${PREFIX}-tooltip`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.benefitContainer}`]: {
    color: theme.palette.secondary.light,
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  [`& .${classes.iconBox}`]: {
    marginRight: theme.spacing(0.75),
    lineHeight: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },
  },
  [`& .${classes.helpIcon}`]: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.secondary.light,
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
  },
}));

const BenefitItem = (props) => {
  const {
    icon, text, details, className,
  } = props;

  return (
    <StyledBox className={className}>
      <Grid container className={`${classes.benefitContainer}`}>
        <Grid item className={`${classes.iconBox}`}>
          {icon}
        </Grid>

        <Grid item lg md sm xs>
          <Typography
            className="text"
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />

          {details && (
            <StyledTooltip title={details} placement="top" arrow>
              <HelpOutlineIcon className={classes.helpIcon} />
            </StyledTooltip>
          )}
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export { BenefitItem };
